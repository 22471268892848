import { MailOutline } from "@material-ui/icons";
import Card from "components/Card/Card";
import "react-datetime/css/react-datetime.css";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/Commission/create.js";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import CustomInput from "components/CustomInput/CustomInput";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { masterApi } from "functions/apis";
import { orderApi } from "functions/apis";
import { Autocomplete } from "@material-ui/lab";
import { useParams, Redirect } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function DeleteOnlineVoucherType(props) {
  const [state, setState] = useState({
    voucherTypeID: null,
    currencyID: null,
    totalPrice: 0,
    effectiveDate: null,
    expiryDate: null,
    trips: []
  });
  const [voucherType, setVoucherType] = useState(null);

  const { id } = useParams();

  const [isDisable, setIsDisable] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [currencyList, setCurrencyList] = useState([]);
  const [voucherTypeList, setVoucherTypeList] = React.useState([]);
  const abortController = React.useRef(new AbortController());

  useEffect(() => {
    orderApi
      .get("OnlineVoucherTypes/" + id, {
        signal: abortController.current.signal
      })
      .then(resp => {
        setState({
          ...state,
          currencyID: resp.currency?.id,
          effectiveDate: new Date(resp.effectiveDate),
          expiryDate: new Date(resp.expiryDate),
          totalPrice: resp.totalPrice,
          trips: resp.voucherTypeTrips,
          voucherTypeID: resp.voucherType.id
        });
        setVoucherType(resp.voucherType);
      });
    orderApi
      .get(`/VoucherTypes`, {
        signal: abortController.current.signal,
        params: {
          filter: {
            searchString: null,
            sort: 0
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setVoucherTypeList(data.records))
      .catch(error => console.log(error));
    masterApi
      .get("/Currencies", {
        signal: abortController.current.signal,
        params: {
          filter: {
            publicationStatus: 1
          },
          pagination: {
            pageIndex: 0,
            pageSize: 0
          }
        }
      })
      .then(data => setCurrencyList(data.records))
      .catch(error => console.log(error));

    return () => abortController.current.abort();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setIsDisable(true);
    orderApi
      .delete("OnlineVoucherTypes/" + id, {
        signal: abortController.current.signal
      })
      .then(() => setRedirect(true))
      .catch(error => {
        console.log(error);
        setIsDisable(false);
      });
  };

  const classes = useStyles();

  return redirect ? (
    <Redirect to={"/admin/OnlineVoucherType/List"} />
  ) : (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              DELETE ONLINE VOUCHER TYPE
            </h4>
          </CardHeader>
          <CardBody>
            <i>*Are you sure want to delete this online voucher type?</i>
            <form onSubmit={handleSubmit}>
              <Button onClick={() => props.history.goBack()} value="Back">
                BACK
              </Button>
              <Button
                disabled={isDisable}
                color="rose"
                type="submit"
                value="Submit"
              >
                DELETE
              </Button>
            </form>
            <br />
            <br />
            <GridContainer>
              <GridItem xs={12} md={12}>
                <Autocomplete
                  id="voucherType"
                  options={voucherTypeList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={option => option.name}
                  renderOption={option => (
                    <React.Fragment>
                      <b>{option.code}</b> - {option.name}
                    </React.Fragment>
                  )}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Voucher Type"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        value: voucherType ? voucherType.code : "",
                        readOnly: true
                      }}
                      disabled={true}
                    />
                  )}
                  value={voucherType ? { ...voucherType, name: "" } : null}
                  disabled={true}
                />
              </GridItem>
              <GridItem xs={12} md={12}>
                <FormControl
                  disabled
                  fullWidth
                  className={classes.selectFormControl}
                >
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    Currency
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={state.currencyID === null ? "" : state.currencyID}
                    disabled
                  >
                    {currencyList.map(record => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={record.id}
                          key={record.id}
                        >
                          <b>{record.code}</b>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={12}>
                <CustomInput
                  labelText="Total Price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: state.totalPrice,
                    type: "number",
                    disabled: true
                  }}
                />
              </GridItem>
              <GridItem xs={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    value={state.effectiveDate}
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Effective Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} md={6}>
                <FormControl fullWidth>
                  <Datetime
                    value={state.expiryDate}
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: "Expiry Date",
                      disabled: true
                    }}
                  />
                </FormControl>
              </GridItem>
              {state.trips.map(record => {
                return (
                  <GridItem key={record.id} xs={12}>
                    <GridContainer key={record.voucherTypeTripID}>
                      <GridItem xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText="Trip"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: record.description
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Ticket"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.ticket,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="PDF"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.pdf,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Terminal Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.terminalFee,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Surcharge"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.surcharge,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="Confirmation Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.confirmationFee,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText="VTL Fee"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            value: record.pricing.vtlFee,
                            disabled: true
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={4} md={2}>
                        <CustomInput
                          labelText={<b>Total</b>}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            type: "number",
                            disabled: true,
                            value:
                              record.pricing.ticket +
                              record.pricing.pdf +
                              record.pricing.terminalFee +
                              record.pricing.surcharge +
                              record.pricing.confirmationFee +
                              record.pricing.vtlFee
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                );
              })}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

DeleteOnlineVoucherType.propTypes = {
  history: PropTypes.object
};
