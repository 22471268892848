import LoginPage from "views/Pages/LoginPage.js";

//ui
import ChangePassword from "views/Profile/ChangePassword";

import { dashboard } from "routes/dashboard";
import { profile, profileHidden } from "routes/profile";
import { security, userHidden, roleHidden } from "routes/security";
import {
  notificationList,
  notificationListHidden,
  notificationConfigHidden
} from "routes/notification";
import { adjustment, adjustmentHidden } from "routes/adjustment";
import { commission, commissionHidden } from "routes/commission";
import { exchangeRate, exchangeRateHidden } from "routes/exchange_rate";
import { topUp, topUpHidden } from "routes/top_up";
import { order, orderHidden } from "routes/order";
import {
  orderCancellation,
  orderCancellationHidden
} from "routes/order_cancellation";
import {
  agentBookingCancellation,
  agentBookingCancellationHidden
} from "routes/agent_booking_cancellation";
import {
  agentBookingTransfer,
  agentBookingTransferHidden
} from "routes/agent_booking_transfer";
import { agent, agentHidden } from "routes/agent";
import { counter, counterHidden } from "routes/counter";
import { directIssuance, directIssuanceHidden } from "routes/direct_issuances";
import { voidTransaction, voidTransactionHidden } from "routes/void";
import { voucherType, voucherTypeHidden } from "routes/voucher_type";
import { bookingType, bookingTypeHidden } from "routes/booking_type";
import {
  onlineBookingPricing,
  onlineBookingPricingHidden
} from "routes/online_booking_pricing";
import { coreApiCheckIn, coreApiCheckInHidden } from "routes/core_api_check_in";
import { report } from "routes/report";
import { peakHourTrip, peakHourTripHidden } from "routes/peak_hour_trip";
import {
  onlineBookingPromoCodeHidden,
  onlineBookingPromoCode
} from "routes/online_booking_promo_code";
import { onlineBooking, onlineBookingHidden } from "routes/online_booking";
import { feePolicyDetailHidden } from "routes/fee_policy";
import { feePolicy } from "routes/fee_policy";
import { feePolicyGroup, feePolicyGroupHidden } from "routes/fee_policy_group";
import { merchant, merchantHidden } from "routes/merchant";
import { merchantPromo } from "routes/merchant_promo";
import { merchantPromoHidden } from "routes/merchant_promo";
import {
  appsNotification,
  appsNotificationHidden
} from "routes/apps_notification";
import {
  appsNotificationSection,
  appsNotificationSectionHidden
} from "routes/apps_notification_section";
import {
  sendAppsNotification,
  sendAppsNotificationHidden
} from "routes/send_apps_notification";
import { merchantInventoryControlGroupHidden } from "routes/merchant_inventory_control_group";
import { merchantProduct } from "routes/merchant_product";
import { merchantProductHidden } from "routes/merchant_product";
import { merchantInventoryGeneration } from "routes/merchant_inventory_generation";
import { merchantInventoryGenerationHidden } from "routes/merchant_inventory_generation";
import { onlineBookingHomeContent } from "routes/online_booking_home_content";
import { onlineBookingHomeContentHidden } from "routes/online_booking_home_content";
import { merchantInventories } from "routes/merchant_inventories";
import { merchantInventoriesHidden } from "routes/merchant_inventories";
import { merchantAgentBookings } from "routes/merchant_agent_booking";
import { merchantAgentBookingsHidden } from "routes/merchant_agent_booking";
import { merchantInventoryAdjustments } from "routes/merchant_inventory_adjustment";
import { merchantInventoryAdjustmentsHidden } from "routes/merchant_inventory_adjustment";
import { onlineVoucherType } from "routes/online_voucher_type";
import { onlineVoucherTypeHidden } from "routes/online_voucher_type";
// import {
//   merchantOnlineBooking,
//   merchantOnlineBookingHidden
// } from "routes/merchant_online_booking";
// import {
//   merchantOnlineBookingPricing,
//   merchantOnlineBookingPricingHidden
// } from "routes/merchant_online_booking_pricing";

var dashRoutes = []
  .concat(dashboard())
  .concat(profile())
  .concat(profileHidden())
  .concat(security())
  .concat(userHidden())
  .concat(roleHidden())
  .concat(notificationList())
  .concat(notificationListHidden())
  .concat(notificationConfigHidden())
  .concat(adjustment())
  .concat(peakHourTrip())
  .concat(onlineBookingPromoCode())
  .concat(onlineBookingPromoCodeHidden())
  .concat(onlineBooking())
  .concat(onlineBookingHidden())
  .concat(peakHourTripHidden())
  .concat(feePolicyGroup())
  .concat(feePolicyGroupHidden())
  .concat(feePolicy())
  .concat(feePolicyDetailHidden())
  .concat(adjustmentHidden())
  .concat(commission())
  .concat(commissionHidden())
  .concat(exchangeRate())
  .concat(exchangeRateHidden())
  .concat(topUp())
  .concat(topUpHidden())
  .concat(coreApiCheckIn())
  .concat(coreApiCheckInHidden())
  .concat(order())
  .concat(orderHidden())
  .concat(orderCancellation())
  .concat(orderCancellationHidden())
  .concat(agentBookingCancellation())
  .concat(agentBookingCancellationHidden())
  .concat(agentBookingTransfer())
  .concat(agentBookingTransferHidden())
  .concat(agent())
  .concat(agentHidden())
  .concat(counter())
  .concat(counterHidden())
  .concat(directIssuance())
  .concat(directIssuanceHidden())
  .concat(voidTransaction())
  .concat(voidTransactionHidden())
  .concat(voucherType())
  .concat(voucherTypeHidden())
  .concat(bookingType())
  .concat(bookingTypeHidden())
  .concat(appsNotification())
  .concat(appsNotificationHidden())
  .concat(appsNotificationSection())
  .concat(appsNotificationSectionHidden())
  .concat(sendAppsNotification())
  .concat(sendAppsNotificationHidden())
  .concat(onlineBookingHomeContent())
  .concat(onlineBookingHomeContentHidden())
  .concat(onlineBookingPricing())
  .concat(onlineBookingPricingHidden())
  .concat(merchant())
  .concat(merchantHidden())
  .concat(merchantPromo())
  .concat(merchantPromoHidden())
  .concat(merchantInventoryControlGroupHidden())
  .concat(merchantProduct())
  .concat(merchantProductHidden())
  .concat(merchantInventoryGeneration())
  .concat(merchantInventoryGenerationHidden())
  .concat(merchantInventories())
  .concat(merchantInventoriesHidden())
  .concat(merchantAgentBookings())
  .concat(merchantAgentBookingsHidden())
  .concat(merchantInventoryAdjustments())
  .concat(merchantInventoryAdjustmentsHidden())
  // .concat(merchantOnlineBookingPricing())
  // .concat(merchantOnlineBookingPricingHidden())
  // .concat(merchantOnlineBooking())
  // .concat(merchantOnlineBookingHidden())
  .concat(onlineVoucherType())
  .concat(onlineVoucherTypeHidden())
  .concat(report())
  .concat([
    {
      collapse: true,
      name: "Sidebar Hidden",
      state: "sidebarHidden",
      hideInSidebar: true,
      views: [
        {
          path: "/login",
          name: "Login",
          component: LoginPage,
          layout: "/auth"
        },
        {
          path: "/Profile/ChangePassword",
          name: "Change Password",
          component: ChangePassword,
          layout: "/admin"
        }
      ]
    } //Other Hidden
  ]);
export default dashRoutes;
