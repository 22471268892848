import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DownloadIcon from "@material-ui/icons/CloudDownload";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { travelApi } from "functions/apis";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import { formatDateTime } from "functions/Helper";
import AgentDialog from "components/AgentDialog/AgentDialog";
import { formatCurrency } from "functions/Helper";
import axios from "axios";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const useStyles = makeStyles(styles);

export default function AmendmentMerchantAgentBookingList() {
  const amendmentMerchantAgentBookingListSignal = useRef(new AbortController());
  useEffect(() => {
    return () => {
      amendmentMerchantAgentBookingListSignal.current.abort();
    };
  }, []);

  const [data, setData] = React.useState([]);
  const [pages, setPages] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    pageIndex: 1,
    pageSize: 10
  });
  const [filter, setFilter] = React.useState({
    searchString: "",
    agentID: null,
    sort: 0,
    status: 1,
    processStatus: 2
  });

  const [agentData, setAgentData] = React.useState({
    id: "",
    name: ""
  });

  useEffect(() => {
    if (agentData.id != "") {
      setFilter({ ...filter, productID: agentData.id });
    }
  }, [agentData]);

  const [showAgentModal, setShowAgentModal] = React.useState(false);
  const handleAgentIDChanged = e => {
    if (e) {
      setAgentData({ id: e.id, name: e.name });
      setShowAgentModal(false);
    }
  };
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setFilter({
      ...filter,
      productID: agentData.id
    });
  }, [agentData]);

  useEffect(() => {
    setLoading(true);
    travelApi
      .get("/AgentBookings", {
        signal: amendmentMerchantAgentBookingListSignal.current.signal,
        params: {
          filter: filter,
          pagination: pagination
        }
      })
      .then(data => {
        setData(
          data.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <Link to={"/admin/MerchantAgentBooking/Detail/" + record.id}>
                    <Button color="info" className="like">
                      <VisibilityIcon /> VIEW
                    </Button>
                  </Link>
                  <Button
                    color="info"
                    className="like"
                    onClick={() =>
                      axios
                        .get(
                          process.env.REACT_APP_MERCHANT_API_URL +
                            "/AgentBookings/" +
                            record.id +
                            "/ExportBookingPaxes",
                          {
                            responseType: "arraybuffer",
                            headers: {
                              Authorization: localStorage.getItem(
                                "Authorization"
                              )
                            }
                          }
                        )
                        .then(response => {
                          const url = window.URL.createObjectURL(
                            new Blob([response.data])
                          );
                          const link = document.createElement("a");
                          link.href = url;
                          link.setAttribute("download", record.no + ".xlsx");
                          document.body.appendChild(link);
                          link.click();
                        })
                    }
                  >
                    <DownloadIcon />
                    DOWNLOAD
                  </Button>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(data.totalRecords / pagination.pageSize));
        setLoading(false);
      })
      .catch(error => console.log(error));
  }, [pagination, filter]);

  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4
              className={classes.cardIconTitle}
            >{`MERCHANT AGENT BOOKINGS LIST - COMPLETE WITH AMENDMENT`}</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selAgent"
                    className={classes.selectLabel}
                  >
                    AGENT
                  </InputLabel>
                  <Input
                    onClick={() => setShowAgentModal(prev => !prev)}
                    className={classes.select}
                    value={agentData ? agentData.name : ""}
                  />
                  <AgentDialog
                    openModal={showAgentModal}
                    setOpenModal={val => setShowAgentModal(val)}
                    onSelect={handleAgentIDChanged}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <br />
            <ReactTable
              manual
              pages={pages}
              page={pagination.pageIndex - 1}
              pageSize={pagination.pageSize}
              onPageChange={page =>
                setPagination({
                  pageIndex: page + 1,
                  pageSize: pagination.pageSize
                })
              }
              onPageSizeChange={(pageSize, page) => {
                setPagination({ pageSize: pageSize, pageIndex: page + 1 });
              }}
              loading={loading}
              data={data}
              filterable={false}
              columns={[
                {
                  Header: "Booking No",
                  id: "no",
                  accessor: d => d.no
                },
                {
                  Header: "Submitted By",
                  accessor: "submittedBy"
                },
                {
                  Header: "Email Confirmation",
                  accessor: "emailConfirmation"
                },
                {
                  Header: "Amendment Note",
                  accessor: "processStatusRemarks"
                },
                {
                  Header: "Transaction Time",
                  id: "transactionTime",
                  accessor: d =>
                    formatDateTime(new Date(d.transactionTime + "Z"))
                },
                {
                  Header: "Total",
                  id: "total",
                  accessor: d =>
                    formatCurrency(
                      d.agent.billingCurrency === null
                        ? "XXX"
                        : d.agent.billingCurrency.code,
                      d.total
                    )
                },
                {
                  Header: "Actions",
                  accessor: "actions",
                  sortable: false,
                  filterable: false
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
