import { FormControl, Input, InputLabel, makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import { Redirect } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import { travelApi } from "functions/apis";
import CustomInput from "components/CustomInput/CustomInput";
import MerchantControlGroupDialog from "components/MerchantControlGroupDialog/MerchantControlGroupDialog";
import { toast, ToastContainer } from "react-toastify";

const useStyles = makeStyles(styles);

const CreateMerchantInventoryGeneration = props => {
  const classes = useStyles();

  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [input, setInput] = useState({
    controlGroupID: null,
    startDate: null,
    untilDate: null,
    quantity: 0
  });
  const [controlGroupData, setControlGroupData] = React.useState({
    id: "",
    name: ""
  });
  const [isShowDialog, setIsShowDialog] = React.useState(false);

  const CreateMerchantInventoryGenerationSignal = useRef(new AbortController());

  const isValidDate = dateString => {
    if (typeof dateString === "string") {
      dateString = dateString.trim();
    }
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
  };

  useEffect(() => {
    return () => CreateMerchantInventoryGenerationSignal.current.abort();
  }, []);

  const handleStartDateChanged = e => {
    if (isValidDate(e)) {
      setInput(prev => ({
        ...prev,
        startDate: e === "" ? null : e
      }));
    }
  };

  const handleUntilDateChanged = e => {
    if (isValidDate(e)) {
      setInput(prev => ({
        ...prev,
        untilDate: e === "" ? null : e
      }));
    }
  };

  const handleQuantityChanged = e => {
    let newQuantity = parseInt(e.target.value);
    if (newQuantity !== input.quantity)
      setInput({
        ...input,
        quantity: newQuantity
      });
  };

  const handleControlGroupIDChanged = e => {
    setControlGroupData({ ...controlGroupData, id: e.id, name: e.name });
    setIsShowDialog(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    let body = { ...input };
    body.controlGroupID = controlGroupData.id;
    let isValid = true;
    let message =
      "One or more validation errors occurred. Please check your data.";

    if (body.controlGroupID == null || body.controlGroupID == "") {
      isValid = false;
      message =
        "One or more validation errors occurred. Control Group ID can't be null.";
    }

    const dateTemp = new Date();
    dateTemp.setHours(0);
    dateTemp.setMinutes(0);
    if (body.startDate < dateTemp) {
      isValid = false;
      message =
        "One or more validation errors occurred. StartDate cannot be past date.";
    }

    if (body.untilDate < body.startDate) {
      isValid = false;
      message =
        "One or more validation errors occurred. Until date must be greater than start date.";
    }

    if (body.quantity < 1) {
      isValid = false;
      message =
        "One or more validation errors occurred. Quantity must be greater than 0";
    }

    if (isValid) {
      setDisabled(true);
      if (body.startDate) {
        body.startDate = body.startDate.toISOString();
      }

      if (body.untilDate) {
        body.untilDate = body.untilDate.toISOString();
      }
      const resolveFromTravelApi = new Promise((resolve, reject) => {
        travelApi
          .post("inventoryGenerations", body, {
            signal: CreateMerchantInventoryGenerationSignal.current.signal
          })
          .then(() => {
            resolve({ isSuccess: true });
          })
          .catch(error => {
            reject({ isSuccess: false, error });
          });
      });

      toast
        .promise(resolveFromTravelApi, {
          pending: "...Submit data",
          success: {
            render({ data }) {
              if (data.isSuccess) {
                return `Success submit data`;
              } else {
                return `Error submit data: ${JSON.stringify(
                  data.error?.response.data.title
                )}`;
              }
            }
          },
          error: {
            render({ data }) {
              return `Error submit data: ${JSON.stringify(
                data.error?.response.data.title
              )}`;
            }
          }
        })
        .then(resp => {
          if (resp && resp.isSuccess) {
            setRedirect(true);
          }
        })
        .finally(() => {
          setDisabled(false);
        });
    } else {
      toast.error(message);
    }
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantsProductGenerations/Pending"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              CREATE MERCHANT INVENTORY GENERATION
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Back">
              BACK
            </Button>
            <form onSubmit={handleSubmit}>
              <GridContainer>
                <GridItem xs={12} sm={12}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="selAgent"
                      className={classes.selectLabel}
                    >
                      Control Group
                    </InputLabel>
                    <Input
                      classes={{
                        input: classes.input,
                        disabled: classes.active,
                        root: classes.active,
                        underline: classes.underline
                      }}
                      value={controlGroupData.name ?? ""}
                      onClick={() => setIsShowDialog(true)}
                      fullWidth
                    />
                  </FormControl>
                  <MerchantControlGroupDialog
                    onSelect={handleControlGroupIDChanged}
                    openModal={isShowDialog}
                    setOpenModal={isOpenModal => setIsShowDialog(isOpenModal)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.startDate}
                      onChange={handleStartDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      utc={true}
                      inputProps={{
                        placeholder: "Start Date"
                      }}
                    />
                  </FormControl>
                </GridItem>

                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth>
                    <Datetime
                      value={input.untilDate}
                      onChange={handleUntilDateChanged}
                      closeOnSelect={true}
                      timeFormat={false}
                      utc={true}
                      inputProps={{
                        placeholder: "Until Date"
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12}>
                  <CustomInput
                    labelText="Quantity"
                    id="numQuantity"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "number",
                      onChange: handleQuantityChanged,
                      value: input.quantity
                    }}
                  />
                </GridItem>

                <Button
                  disabled={disabled}
                  color="rose"
                  type="submit"
                  value="Submit"
                >
                  Submit
                </Button>
              </GridContainer>
            </form>
          </CardBody>
        </Card>
      </GridItem>
      <ToastContainer theme="light" />
    </GridContainer>
  );
};

export default CreateMerchantInventoryGeneration;

CreateMerchantInventoryGeneration.propTypes = {
  history: PropTypes.object
};
