import { makeStyles } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "assets/jss/material-dashboard-pro-react/views/formsStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import MailOutline from "@material-ui/icons/MailOutline";
import CardBody from "components/Card/CardBody";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import CustomInput from "components/CustomInput/CustomInput";
import { travelApi } from "functions/apis";
import { useParams, Redirect } from "react-router-dom";
import { formatCurrency } from "functions/Helper";
import { formatDateTime } from "functions/Helper";
import ViewMerchantAgentBookingDetail from "./ViewMerchantAgentBookingDetail";

const useStyles = makeStyles(styles);

const statusList = ["Pending", "Submitted"];

const AmendmentMerchantAgentBooking = props => {
  const classes = useStyles();

  const [input, setInput] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const { id } = useParams();

  const amendmentMerchantAgentBookingSignal = useRef(new AbortController());

  useEffect(() => {
    travelApi
      .get("AgentBookings/" + id, {
        signal: amendmentMerchantAgentBookingSignal.current.signal
      })
      .then(resp => {
        setInput(resp);
      });
    return () => amendmentMerchantAgentBookingSignal.current.abort();
  }, []);

  const handleRemarksChanged = e => {
    setRemarks(e.target.value);
  };

  const handleSubmitCompleteWithAmendment = e => {
    e.preventDefault();
    setDisabled(true);
    travelApi
      .post(
        "AgentBookings/" + id + "/SetAsCompletedWithAmendment",
        {
          remarks
        },
        {
          signal: amendmentMerchantAgentBookingSignal.current.signal
        }
      )
      .then(() => {
        setRedirect(true);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return redirect ? (
    <Redirect to={"/admin/MerchantAgentBooking/CompleteWithAmendmentList"} />
  ) : (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              COMPLETE WITH AMENDMENT MERCHANT AGENT BOOKING
            </h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => props.history.goBack()} value="Cancel">
              CANCEL
            </Button>
            <form onSubmit={handleSubmitCompleteWithAmendment}>
              <GridContainer>
                <GridItem xs={6}>
                  <CustomInput
                    labelText="Amendment Note"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      value: remarks,
                      onChange: handleRemarksChanged
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button
                disabled={disabled}
                color="rose"
                type="submit"
                value="Submit"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <MailOutline />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>
              MERCHANT AGENT BOOKING DETAIL
            </h4>
          </CardHeader>
          <CardBody>
            {input != null && (
              <form>
                <GridContainer>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Booking No"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.no,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Status"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: statusList[input.status],
                        disabled: true
                      }}
                    />
                  </GridItem>
                  {input.status == 1 && (
                    <GridItem xs={6}>
                      <CustomInput
                        labelText="Submitted At"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: formatDateTime(
                            new Date(input.submittedAt + "Z")
                          ),
                          disabled: true
                        }}
                      />
                    </GridItem>
                  )}
                  {input.status == 1 && (
                    <GridItem xs={6}>
                      <CustomInput
                        labelText="Submitted By"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: input.submittedBy,
                          disabled: true
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Email Confirmation"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.emailConfirmation,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Transaction Time"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: formatDateTime(
                          new Date(input.transactionTime + "Z")
                        ),
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value:
                          input == null
                            ? ""
                            : formatCurrency(
                                input.agent.billingCurrency === null
                                  ? "XXX"
                                  : input.agent.billingCurrency.code,
                                input.total
                              ),
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText="Remarks"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.remarks,
                        disabled: true
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <CustomInput
                      labelText="Agent"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        value: input.agent.name,
                        disabled: true
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            )}
          </CardBody>
        </Card>
        {input &&
          input.details.length > 0 &&
          input.details.map((val, key) => (
            <ViewMerchantAgentBookingDetail key={key} data={val} />
          ))}
      </GridItem>
    </GridContainer>
  );
};

export default AmendmentMerchantAgentBooking;

AmendmentMerchantAgentBooking.propTypes = {
  history: PropTypes.object
};
