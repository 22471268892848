import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ViewMerchantAgentBooking from "views/MerchantAgentBooking/ViewMerchantAgentBooking";
import WaitingMerchantAgentBookingList from "views/MerchantAgentBooking/WaitingMerchantAgentBookingList";
import CompletedMerchantAgentBookingList from "views/MerchantAgentBooking/CompletedMerchantAgentBookingList";
import AmendmentMerchantAgentBookingList from "views/MerchantAgentBooking/AmendmentMerchantAgentBookingList";
import FailedMerchantAgentBookingList from "views/MerchantAgentBooking/FailedMerchantAgentBookingList";
import CompleteMerchantAgentBooking from "views/MerchantAgentBooking/CompletedMerchantAgentBooking";
import AmendmentMerchantAgentBooking from "views/MerchantAgentBooking/AmendmentMerchantAgentBooking";
import FailedMerchantAgentBooking from "views/MerchantAgentBooking/FailedMerchantAgentBooking";

const merchantAgentBookings = () => {
  let views = [
    {
      path: "/MerchantAgentBooking/WaitingList",
      name: "Waiting",
      mini: "WA",
      component: WaitingMerchantAgentBookingList,
      layout: "/admin"
    },
    {
      path: "/MerchantAgentBooking/CompletedList",
      name: "Completed",
      mini: "CM",
      component: CompletedMerchantAgentBookingList,
      layout: "/admin"
    },
    {
      path: "/MerchantAgentBooking/CompleteWithAmendmentList",
      name: "Complete With Amendment",
      mini: "CWA",
      component: AmendmentMerchantAgentBookingList,
      layout: "/admin"
    },
    {
      path: "/MerchantAgentBooking/FailedList",
      name: "Failed",
      mini: "FA",
      component: FailedMerchantAgentBookingList,
      layout: "/admin"
    }
  ];
  return [
    {
      collapse: true,
      name: "M Agent Booking",
      icon: AssignmentLateIcon,
      state: "merchantAgentBookings",
      views: views
    }
  ];
};

const merchantAgentBookingsHidden = () => {
  return [
    {
      collapse: true,
      name: "Merchant Agent Booking Hidden",
      state: "merchantAgentBookingHiddenCollapse",
      hideInSidebar: true,
      views: [
        {
          path: "/MerchantAgentBooking/Detail",
          name: "Merchant Agent Booking Detail",
          component: ViewMerchantAgentBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantAgentBooking/Complete",
          name: "Complete Merchant Agent Booking",
          component: CompleteMerchantAgentBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantAgentBooking/Amendment",
          name: "Complete With Amendment Merchant Agent Booking",
          component: AmendmentMerchantAgentBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        },
        {
          path: "/MerchantAgentBooking/Failed",
          name: "Failed With Amendment Merchant Agent Booking",
          component: FailedMerchantAgentBooking,
          layout: "/admin",
          params: [
            {
              name: "id"
            }
          ]
        }
      ]
    }
  ];
};

export { merchantAgentBookings, merchantAgentBookingsHidden };
