import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import { Assignment } from "@material-ui/icons";
import CardBody from "components/Card/CardBody";
import ReactTable from "react-table";
import { formatDate } from "functions/Helper";
import EditIcon from "@material-ui/icons/Edit";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import CustomInput from "components/CustomInput/CustomInput";
import { orderApi } from "functions/apis";
const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

const sortOptionsList = [
  "VOUCHER_TYPE_CODE_ASC",
  "VOUCHER_TYPE_CODE_DESC",
  "VOUCHER_TYPE_NAME_ASC",
  "VOUCHER_TYPE_NAME_DESC",
  "CREATED_AT_ASC",
  "CREATED_AT_DESC"
];

const useStyles = makeStyles(styles);

const OnlineVoucherTypeList = () => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(0);
  const [filter, setFilter] = useState({
    searchString: "",
    sortOptions: 0
  });
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10
  });

  const onlineVoucherTypeListSignal = React.useRef(new AbortController());

  React.useEffect(() => {
    orderApi
      .get("onlineVoucherTypes", {
        params: {
          filter,
          pagination
        },
        signal: onlineVoucherTypeListSignal.current.signal
      })
      .then(resp => {
        setData(
          resp.records.map(record => {
            return {
              ...record,
              actions: (
                <div className="actions-right">
                  <div className="actions-right">
                    <Link to={"/admin/OnlineVoucherType/Detail/" + record.id}>
                      <Button color="info" className="like">
                        <VisibilityIcon /> VIEW
                      </Button>
                    </Link>
                    <Link to={"/admin/OnlineVoucherType/Edit/" + record.id}>
                      <Button color="danger" className="remove">
                        <EditIcon /> EDIT
                      </Button>
                    </Link>
                    <Link to={"/admin/OnlineVoucherType/Delete/" + record.id}>
                      <Button color="danger" className="remove">
                        <DeleteIcon /> DELETE
                      </Button>
                    </Link>
                  </div>
                </div>
              )
            };
          })
        );
        setPages(Math.ceil(resp.totalRecords / pagination.pageSize));
        setLoading(false);
      });
  }, [filter, pagination]);

  const handleSearchStringChanged = e =>
    setFilter({
      ...filter,
      SearchString: e.target.value === "" ? null : e.target.value
    });

  const handleSortOptionsChanged = e => {
    setFilter({ ...filter, sortOptions: e.target.value });
    // setState({ ...state, CurrencyID: e.target.value });
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>ONLINE VOUCHER TYPE LIST</h4>
          </CardHeader>
          <CardBody>
            <Link to={"/admin/OnlineVoucherType/Create"}>
              <Button color="rose" type="button" value="Create">
                Create
              </Button>
            </Link>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="selCurrency"
                    className={classes.selectLabel}
                  >
                    SORT BY
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={filter.sortOptions ?? ""}
                    onChange={handleSortOptionsChanged}
                  >
                    {sortOptionsList.map((record, key) => {
                      return (
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={key}
                          key={key}
                        >
                          <p>{record}</p>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} md={6}>
                <CustomInput
                  labelText="Search"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: handleSearchStringChanged,
                    value: filter.SearchString ?? ""
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridItem>
              <ReactTable
                manual
                pages={pages}
                page={pagination.pageIndex - 1}
                pageSize={pagination.pageSize}
                onPageChange={page =>
                  setPagination({
                    pageIndex: page + 1,
                    pageSize: pagination.pageSize
                  })
                }
                onPageSizeChange={(pageSize, page) => {
                  setPagination({ pageSize: pageSize, pageIndex: page + 1 });
                }}
                loading={loading}
                data={data}
                filterable={false}
                columns={[
                  {
                    id: "code",
                    Header: "Code",
                    accessor: d => d.voucherType.code
                  },
                  {
                    id: "name",
                    Header: "Name",
                    accessor: d => d.voucherType.name
                  },
                  {
                    id: "createdAt",
                    Header: "createdAt",
                    accessor: d => formatDate(d.createdAt)
                  },
                  {
                    id: "effectiveDate",
                    Header: "Effective Date",
                    accessor: d => formatDate(d.effectiveDate)
                  },
                  {
                    Header: "Actions",
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default OnlineVoucherTypeList;
